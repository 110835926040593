import React from "react"
const darkBlock = ({ children, padding = '32px' }) => {
    return (
        <div style={{ color: 'white' }}>
            <div style={{
                backgroundColor: '#212529',
                padding: padding,
                borderRadius: 16,
                boxShadow: '0px 14px 22px -9px rgba(16, 25, 40, 0.14), 0px 0px 3px -1px rgba(16, 25, 40, 0.04)'
            }}>
                {children}
            </div>
        </div>
    )
}

export default darkBlock;
