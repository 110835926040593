import "./App.css"
import React from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import About from "./components/About"
import Projects from "./components/Projects"
import Contact from "./components/Contact"
import {Container, Nav, Navbar} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Footer from "./components/Footer"

function App() {
	return (
		<div style={{ backgroundColor: 'rgb(234, 234, 234)'}}>
			<div className="d-flex flex-column min-vh-100 text-center">
				<Navbar bg="dark" variant="dark" expand="lg" className="navigation">
					<Container>
						<Navbar.Brand>William Chalifoux&apos;s Portfolio</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="me-auto">
								<Nav.Link href="/about" className="nav-link">About Me</Nav.Link>
								<Nav.Link href="/projects" className="nav-link">Projects</Nav.Link>
								<Nav.Link href="/contact" className="nav-link">Contact Me</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				<>
					<div className="flex-grow-1" style={{paddingTop: '48px', paddingBottom: '48px' }}>
						<BrowserRouter>
							<Routes>
								<Route index element={<About />} />
								<Route path="/about" element={<About />} />
								<Route path="/contact" element={<Contact />}  className="flex-grow-1" />
								<Route path="/projects" element={<Projects />} />
							</Routes>
						</BrowserRouter>
					</div>
					<Footer />
				</>
			</div>
		</div>
	)
}

export default App
