import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import {
	FaBootstrap,
	FaCss3,
	FaFilePdf,
	FaGit,
	FaHtml5,
	FaJava,
	FaJs,
	FaNodeJs,
	FaPython,
	FaReact,
	FaSwift
} from "react-icons/fa"
import picture from "./William.jpg"
import pdfUrl from "./CV_William_Chalifoux_EN.pdf"
import Block from "./block"
import DarkBlock from "./DarkBlock"

const About = () => {
	const downloadPdf = () => {
		const link = document.createElement("a")
		link.href = pdfUrl
		link.download = "CV_William_Chalifoux_EN.pdf"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	return (
		<Container>
			<div style={{paddingBottom: 48}}>
				<Block>
					<Row className="d-flex gy-3">
						<Col md={6} className="gy-5 d-flex flex-column" style={{ textAlign: 'left' }}>
							<h1>About Me</h1>
							<p>Hello! I am William Chalifoux, a passionate and dedicated Full Stack Developer.</p>
							<p>I love creating web and mobile applications that provide meaningful and delightful experiences to users. I bring a unique perspective to problem-solving and enjoy collaborating with teams to bring ideas to life.</p>
							<div className="mt-auto">
								<button className="btn btn-primary" style={{padding: 16}} onClick={downloadPdf}><FaFilePdf /> Download Resume</button>
							</div>
						</Col>
						<Col md={6}>
							<img src={picture} alt="Profile" style={{ borderRadius: 20, maxHeight: 340 }} />
						</Col>
					</Row>
				</Block>
			</div>
			<DarkBlock>
				<Row className="d-flex gy-3" style={{paddingTop: '64px', paddingBottom: '32px'}}>
					<Col md={6} className="gy-7" style={{ textAlign: 'left' }}>
						<h1 className="text-white pb-4">Skills</h1>
						<p style={{marginBottom: 0}}>Here is a list of some languages I have learned throughout the years:</p>
					</Col>
					<Col md={6}>
						<div className="d-flex flex-wrap gap-2">
							<span className="badge bg-primary" style={{padding: 16}}><FaHtml5 /> HTML5</span>
							<span className="badge bg-success"><FaCss3 /> CSS3</span>
							<span className="badge bg-warning"><FaJs /> JavaScript</span>
							<span className="badge bg-info"><FaReact /> React.js</span>
							<span className="badge bg-danger"><FaNodeJs /> Node.js</span>
							<span className="badge bg-secondary"><FaNodeJs /> Express.js</span>
							<span className="badge bg-black"><FaBootstrap /> Bootstrap</span>
							<span className="badge bg-info"><FaGit /> Git</span>
							<span className="badge bg-warning"><FaJava /> Java</span>
							<span className="badge bg-danger"><FaSwift /> Swift</span>
							<span className="badge bg-success"><FaPython /> Python</span>
						</div>
					</Col>
				</Row>
			</DarkBlock>
		</Container>
	)
}

export default About
