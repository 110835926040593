import React from "react"

const block = ({ children }) => {
    
    return (
        <div>
            <div style={{ 
                backgroundColor: 'white', 
                padding: '64px 32px', 
                borderRadius: 16,
                boxShadow: '0px 14px 22px -9px rgba(16, 25, 40, 0.14), 0px 0px 3px -1px rgba(16, 25, 40, 0.04)'
            }}>
                {children}
            </div>
        </div>
    )
}

export default block;
