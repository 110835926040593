import React from "react"

const Footer = () => {
	return (
		<footer className="mt-5 py-3 bg-dark text-white">
			<p className="mb-0">&copy; {new  Date().getFullYear().toString()} William Chalifoux. All rights reserved.</p>
		</footer>
	)
}

export default Footer
