import React from "react"
import {Container} from "react-bootstrap"
import {FaEnvelope, FaGithub, FaLinkedin} from "react-icons/fa"
import Block from "./block"
import DarkBlock from "./DarkBlock"

const Contact = () => {
	return (
		<Container>
			<Block>
				<div style={{ textAlign: 'left' }}>
					<h1 style={{marginBottom: 0}}>Contact Me</h1>
					<p style={{ paddingTop: 24, marginBottom: 0 }}>Have a question or want to get in touch? Feel free to reach out!</p>
				</div>
			</Block>
			<div className="d-flex flex-wrap w-full justify-content-center gap-4 pt-5">
				<a href="mailto:william.chalifoux@gmail.com" style={{display: 'flex', justifyContent: 'center', textDecoration: 'none', color: 'inherit'}}>
					<DarkBlock padding="16px">
						<div className="d-flex align-items-center gap-2">
							<FaEnvelope />
							<p style={{marginBottom: 0, fontSize: 24}}>Send an email</p>
						</div>
					</DarkBlock>
				</a>
				<a href="https://www.linkedin.com/in/william-chalifoux/" target="_blank" rel="noopener noreferrer"
					style={{display: 'flex', justifyContent: 'center', textDecoration: 'none', color: 'inherit'}}>
					<DarkBlock padding="16px">
						<div className="d-flex align-items-center gap-2">
							<FaLinkedin />
							<p style={{marginBottom: 0, fontSize: 24}}>Connect with Me</p>
						</div>
					</DarkBlock>
				</a>
				<a href="https://github.com/swafit" target="_blank" rel="noopener noreferrer"
					style={{display: 'flex', justifyContent: 'center', textDecoration: 'none', color: 'inherit'}}>
					<DarkBlock padding="16px">
						<div className="d-flex align-items-center gap-2">
							<FaGithub />
							<p style={{marginBottom: 0, fontSize: 24}}>GitHub</p>
						</div>
					</DarkBlock>
				</a>
			</div>
		</Container>
	)
}

export default Contact
