import React from "react"
import DarkBlock from "./DarkBlock"
import {Col, Container, Row} from "react-bootstrap"
import EducaticImage from "../assets/img/Educatic.png"
import RabiaPatelArtImage from "../assets/img/RabiaPatelArt.png"
const UnBoreMeAppLink = "https://apps.apple.com/ca/app/unboreme/id6474141135"
const RabiaPatelArtLink = "https://rabiapatelart.com"
const EducaticLink = "https://educatic.site"
const GnochonMSLink = "https://www.youtube.com/embed/sRhVrS5ReCg"

const Projects = () => {
	return (
		<Container>
			<div className="text-center">
				<h1>Projects</h1>
				<p style={{ fontSize: '24px', paddingBottom: '20px' }}>Explore some of my featured projects below.</p>
				<div className="project-container d-grid" style={{ gap: '48px' }}>
					<div>
						<h3 className="pb-4"><strong>
							<a href={UnBoreMeAppLink} target="_blank" rel="noopener noreferrer">UnBoreMe</a></strong>
						</h3>
						<DarkBlock>
							<Row style={{gap: 32}}>
								<Col sm={12} md={6} style={{ display: 'flex' }}>
									<iframe
										width="100%" height="300vh"
										src="https://www.youtube.com/embed/VF3yLdEoJ-A"
										title="UnBore Me demonstration"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
										style={{ border: "1px solid #ddd", borderRadius: "8px" }}
									/>
								</Col>
								<Col sm={12} md={2} style={{ display: 'flex' }}>
									<div className="d-grid flex-column align-items-center">
										<a href={UnBoreMeAppLink} target="_blank" rel="noopener noreferrer" style={{paddingBottom: 16}}><img
											src="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/7c/fc/e1/7cfce187-6a0d-ff12-1b43-3e5a189e8581/AppIcon-1x_U007emarketing-0-7-0-P3-85-220-0.png/1024x1024bb.png"
											alt="App Icon" style={{ width: "200px", height: "200px", marginBottom: "8px", borderRadius:16, border: '1px white solid'}}
										/></a>
										<a href={UnBoreMeAppLink} target="_blank" rel="noopener noreferrer">
											<img
												src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg" // Official App Store badge URL
												alt="Available on the App Store" className="flex" style={{ width: "200px" }} // Adjust the size as needed
											/>
										</a>
									</div>
								</Col>
								<Col sm={12} md={3} style={{ display: 'flex' }}>
									<p className="" style={{width: "100%", height: "100%", padding: 16, borderRadius: 16, border: '1px white solid', flexDirection: 'column',  display: 'inline-flex', justifyContent: 'center'}}>
										UnBoreMe is an iOS app that provides users with a random activity to do when they are bored. It is built using Swift and SwiftUI. The app is available on the App Store.
									</p>
								</Col>
							</Row>
						</DarkBlock>
					</div>

					<div>
						<h3 className="pb-4"><strong>
							<a href={RabiaPatelArtLink} target="_blank" rel="noopener noreferrer">Rabia Patel Art</a></strong>
						</h3>
						<DarkBlock>
							<Row className="d-flex" style={{gap: '32px'}}>
								<Col className="d-flex align-items-stretch">
									<a href={RabiaPatelArtLink} target="_blank" rel="noopener noreferrer" style={{width: "100%", display: 'flex'}}>
										<img src={RabiaPatelArtImage} alt="Rabia Patel Art" style={{ width: "100%", objectFit: "contain", boxShadow: "0px 4px 8px rgba(0,0,0,0.5)", borderRadius: "16px", border: "1px solid white" }} />
									</a>
								</Col>
								<Col className="d-flex align-items-stretch">
									<div style={{width: "100%", padding: 16, borderRadius: 16, border: '1px white solid', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										<p style={{margin: 0}}>
											Rabia Patel Art is an online sales platform for an artist to sell her artworks and was built by a team of 4. Main features of the e-commerce includes authentication, mailing, and payment processing. The project was built using React, MongoDB, SpringBoot, Docker, and more.
										</p>
									</div>
								</Col>
							</Row>
						</DarkBlock>
					</div>

					<div>
						<h3 className="pb-4"><strong>
							<a href={GnochonMSLink} target="_blank" rel="noopener noreferrer">Gnochon Mail System</a></strong>
						</h3>
						<DarkBlock>
							<Row className="d-flex" style={{gap: '32px'}}>
								<Col className="d-flex align-items-stretch">
									<iframe
										width="100%"
										src={GnochonMSLink}
										title="GnochonMSDemo"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
										style={{ border: "1px solid #ddd", borderRadius: "8px" }}
									></iframe>
								</Col>
								<Col className="d-flex align-items-stretch">
									<div style={{width: "100%", padding: 16, borderRadius: 16, border: '1px white solid', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										<p style={{margin: 0}}>
											Gnochon Mail System is a subscription-based mailing system that I independently developed, designed, and implemented. Special focus was put on security measures, such as 2-factor authentication, password hashing, and an encrypted database.
										</p>
									</div>
								</Col>
							</Row>
						</DarkBlock>
					</div>

					<div>
						<h3 className="pb-4"><strong>
							<a href={EducaticLink} target="_blank" rel="noopener noreferrer">Educatic</a></strong>
						</h3>
						<DarkBlock>
							<Row className="d-flex" style={{gap: '32px'}}>
								<Col className="d-flex align-items-stretch">
									<a href={EducaticLink} target="_blank" rel="noopener noreferrer" style={{width: "100%", display: 'flex'}}>
										<img src={EducaticImage} alt="Educatic" style={{ width: "100%", height: "auto", objectFit: "contain", boxShadow: "0px 4px 8px rgba(0,0,0,0.5)", borderRadius: "16px", border: "1px solid white" }} />
									</a>
								</Col>
								<Col className="d-flex align-items-stretch">
									<div style={{width: "100%", padding: 16, borderRadius: 16, border: '1px white solid', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										<p style={{margin: 0}}>
											Educatic is a website dedicated to raising awareness about the scarcity of clean water in Native American territories across Canada. Developed by a team of four, we focused extensively on this critical issue. We are committed to promoting equal rights and ensuring equitable access to essential resources, regardless of gender, religion, or economic status.
										</p>
									</div>
								</Col>
							</Row>
						</DarkBlock>
					</div>

				</div>
			</div>
		</Container>
	)
}

export default Projects
